import React, { useState, useCallback, useEffect } from "react";
import { HeaderLink, RowType } from "../../../components";
import HomeIcon from "@mui/icons-material/Home";
import { useMe } from "../../../store/me";
import {
  GetContractorsInputType,
  GetContractorsOutputType,
  useContractorsApi,
} from "../../../apis";
import { Contractor } from "../../../classes/contractor";
import ContractorTable from "../../../components/ContractorTable";
import ContractorModal from "../../../components/ContractorModal";

export default function Contractors() {
  const { isAdmin } = useMe();
  const { getContractors } = useContractorsApi();
  const [contractor, setContractor] = useState(
    new Contractor({ companyName: "" })
  );
  const [active, setActive] = useState(false);
  const [contractors, setContractors] = useState<GetContractorsOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetContractorsInputType = {
      skip: page * take,
      take,
    };

    if (search) {
      customQuery.search = search;
    }

    const _contractors = await getContractors({ ...customQuery });

    if (page === 0) {
      setContractors(_contractors);
    } else {
      setContractors({
        items: [...contractors.items, ..._contractors.items],
        total: _contractors.total,
      });
    }
  }, [page, take, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = contractors.items.map((contractor) => ({
    contractor,
  }));

  const handleNewContractor = () => {
    setActive(true);
    setContractor(new Contractor({ companyName: "" }));
  };

  const handleView = async (record: RowType) => {
    setContractor(record.contractor as Contractor);
    setActive(true);
  };

  return (
    <>
      {isAdmin() && (
        <HeaderLink
          icon={<HomeIcon />}
          text="Add Contractor"
          onClick={handleNewContractor}
        />
      )}

      <ContractorTable
        rows={rows}
        search={search}
        setSearch={setSearch}
        total={contractors.total}
        setPage={setPage}
        onView={handleView}
      />

      <ContractorModal
        active={active}
        closeModal={() => setActive(false)}
        contractor={contractor}
        onCallback={fetchData}
      />
    </>
  );
}
