import React from "react";
import { Grid, Typography } from "@mui/material";
import RadioButton from "./RadioButton";
import {
  Table,
  TableRow,
  TableBody,
  TableHeader,
  TableSelectedSectionButton,
  TableCell,
  TableSearchField,
  RowType,
} from "./Table";
import {
  useTableFilter,
  LocationFilter,
  ManagersFilter,
  ResidentFilter,
  ResidentStatusFilter,
  ResidentLastUaSort,
  TableFilterModal,
  DistrictFilter,
} from "./TableFilter";

import {
  Resident,
  PENDING,
  REQUEST_MOVE_IN,
  MOVED_IN,
  MOVED_OUT,
} from "../classes/resident";
import { Location } from "../classes/location";
import { convertUTCToLocal } from "../utils/date-helpers";

import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { colors, headerLinkHeight, bottomNavigationHeight } from "../constants";
import {
  faSignInAlt,
  faHome,
  faSignOutAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

interface HeaderStatsProps {
  total: number;
  totalAvailableBeds: number;
}

const HeaderStats = ({ total, totalAvailableBeds }: HeaderStatsProps) => (
  <Grid
    container
    justifyContent="flex-end"
    gap={1}
    sx={{ backgroundColor: colors.grey[6], px: 1 }}
  >
    <Typography variant="body2">
      Available Beds: {totalAvailableBeds}
    </Typography>
    <Typography variant="body2">Count: {total}</Typography>
  </Grid>
);

const Header = () => (
  <>
    <TableCell basis="200px">Name</TableCell>
    <TableCell basis="66px">Loc</TableCell>
    <TableCell basis="120px">Supervision</TableCell>
    <TableCell basis="86px">Last UA</TableCell>
    <TableCell basis="86px">Rent Due</TableCell>
  </>
);

const EmptyView = () => (
  <Typography
    variant="h5"
    align="center"
    sx={{
      color: colors.grey[5],
      marginTop: 8,
    }}
  >
    No Residents
  </Typography>
);

interface RowProps {
  row: RowType;
  selected: boolean;
  onClick: () => void;
  tableBodyRef: any;
}

const Row = ({ row, ...props }: RowProps) => (
  <TableRow row={row} SelectedSectionElem={SelectedSection} {...props}>
    <TableCell basis="200px" semiBold>
      {[row.resident?.lastName, row.resident?.firstName]
        .filter(Boolean)
        .join(", ")}
    </TableCell>
    <TableCell basis="66px" color={colors.grey[3]}>
      {row.location && row.location.name}
    </TableCell>
    <TableCell basis="120px" color={colors.grey[3]}>
      {row.resident && row.resident.supervision}
    </TableCell>
    <TableCell basis="86px" color={colors.grey[3]}>
      {row.resident &&
        row.resident.lastUaDate &&
        format(convertUTCToLocal(row.resident.lastUaDate), "M/dd/yy")}
    </TableCell>
    <TableCell basis="86px" color={colors.grey[3]}>
      {row.resident &&
        row.resident.rentDueDate &&
        format(convertUTCToLocal(row.resident.rentDueDate), "M/dd/yy")}
    </TableCell>
  </TableRow>
);

interface SelectedSectionProps {
  row: RecordProps;
  tableBodyScrollTop: number;
}

const SelectedSection = ({ row, tableBodyScrollTop }: SelectedSectionProps) => {
  const navigate = useNavigate();

  const goTo = (path: string) => () => {
    navigate(`${path}/${row.resident.id}`, {
      state: { tableBodyScrollTop },
    });
  };

  return (
    <>
      {row.resident && row.resident.status === PENDING && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("request-move-in")}
          >
            Req Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === REQUEST_MOVE_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faSignInAlt}
            onClick={goTo("move-in")}
          >
            Move In
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_IN && (
        <>
          <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
            View
          </TableSelectedSectionButton>
          <TableSelectedSectionButton
            icon={faHome}
            onClick={goTo("location-change")}
          >
            Loc Change
          </TableSelectedSectionButton>

          <TableSelectedSectionButton
            icon={faSignOutAlt}
            onClick={goTo("move-out")}
          >
            Move Out
          </TableSelectedSectionButton>
        </>
      )}

      {row.resident && row.resident.status === MOVED_OUT && (
        <TableSelectedSectionButton icon={faEye} onClick={goTo("view")}>
          View
        </TableSelectedSectionButton>
      )}
    </>
  );
};

interface StatusFilterProps {
  callback: () => void;
}
const StatusFilter = ({ callback }: StatusFilterProps) => {
  const { status: value, setStatus } = useTableFilter();

  const _setStatus = (status: string) => {
    setStatus([status]);
    callback && callback();
  };

  return (
    <div style={{ backgroundColor: colors.grey[6] }}>
      <Typography sx={{ marginLeft: 0.5, fontWeight: "bold" }}>
        Group Residents by:
      </Typography>
      <Grid
        container
        wrap="nowrap"
        sx={{ paddingX: 0.5, paddingY: 0.5, gap: "8px" }}
      >
        <RadioButton
          active={value && value[0] === PENDING}
          value={PENDING}
          onClick={_setStatus}
        >
          Pend
        </RadioButton>
        <RadioButton
          active={value && value[0] === REQUEST_MOVE_IN}
          value={REQUEST_MOVE_IN}
          onClick={_setStatus}
        >
          Rq Move In
        </RadioButton>
        <RadioButton
          active={value && value[0] === MOVED_IN}
          value={MOVED_IN}
          onClick={_setStatus}
        >
          Moved In
        </RadioButton>
        <RadioButton
          active={value && value[0] === MOVED_OUT}
          value={MOVED_OUT}
          onClick={_setStatus}
        >
          Moved Out
        </RadioButton>
      </Grid>
    </div>
  );
};

interface RecordProps {
  resident: Resident;
  location?: Location;
}

interface ResidentTableProps {
  rows: RecordProps[];
  total: number;
  totalAvailableBeds: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const ResidentTable = ({
  rows,
  total,
  totalAvailableBeds,
  setPage,
  search,
  setSearch,
}: ResidentTableProps) => {
  const filterContent = [
    ResidentStatusFilter,
    ResidentFilter,
    LocationFilter,
    DistrictFilter,
    ManagersFilter,
    ResidentLastUaSort,
  ];

  return (
    <Table
      style={{
        height: `calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight})`,
      }}
    >
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <StatusFilter callback={() => setPage(0)} />
      <HeaderStats total={total} totalAvailableBeds={totalAvailableBeds} />
      <TableHeader>
        <Header />
      </TableHeader>
      <TableBody
        rows={rows}
        total={total}
        RowElem={Row}
        EmptyViewElem={EmptyView}
        onLoadMore={() => setPage((page) => page + 1)}
      />
    </Table>
  );
};

export default ResidentTable;
