import React, { useState, useCallback } from "react";

import { Dialog, TextField } from "@mui/material";

import { StaticDatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import addDays from "date-fns/addDays";

interface PrintModalProps {
  active: boolean;
  closeModal: () => void;
}

export default function PrintModal({ active, closeModal }: PrintModalProps) {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date | null>(null);

  const handleDate = useCallback(
    (newDate: Date | null) => {
      if (!newDate) return;

      const dateTimeStart = new Date(newDate);
      dateTimeStart.setHours(4, 0, 0, 0);

      const dateTimeEnd = addDays(dateTimeStart, 1);

      const dateTimeStart__gte = dateTimeStart.toISOString();
      const dateTimeEnd__lte = dateTimeEnd.toISOString();

      console.log(dateTimeStart__gte, dateTimeEnd__lte);

      navigate(
        `/print-activity?dateTimeStart__gte=${dateTimeStart__gte}&dateTimeEnd__lte=${dateTimeEnd__lte}`
      );
    },
    [date, navigate]
  );

  return (
    <Dialog open={active} onClose={closeModal}>
      <StaticDatePicker
        label="Date"
        inputFormat="MM/DD/YYYY"
        value={date}
        onChange={(newDate) => setDate(newDate || null)}
        renderInput={(params) => <TextField {...params} fullWidth />}
        onAccept={handleDate}
      />
    </Dialog>
  );
}
