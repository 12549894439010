import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useActivitiesApi,
  GetActivitiesInputType,
  GetActivitiesOutputType,
} from "../../apis/activities";
import { Activity } from "../../classes/activity";
import { Resident } from "../../classes/resident";
import { Location } from "../../classes/location";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";

import styles from "./print.module.css";
import { useGlobalLoading } from "../../components";
import { Curfew, District } from "../../classes";

export default function PrintActivityPage() {
  const navigate = useNavigate();
  const { getActivities } = useActivitiesApi();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { setGlobalLoading } = useGlobalLoading();

  const [activities, setActivities] = useState<GetActivitiesOutputType>({
    items: [],
    total: 0,
  });

  const search = searchParams.get("search") ?? "";
  const activityTypes = searchParams.getAll("activityTypes") ?? [];
  const locations = searchParams.getAll("locations") ?? [];
  const districts = searchParams.getAll("districts") ?? [];

  const dateTimeStart__gte = searchParams.get("dateTimeStart__gte");
  const dateTimeStart__gteDate = dateTimeStart__gte
    ? new Date(dateTimeStart__gte).toISOString()
    : new Date(0).toISOString();

  const dateTimeEnd__lte = searchParams.get("dateTimeEnd__lte");
  const dateTimeEnd__lteDate = dateTimeEnd__lte
    ? new Date(dateTimeEnd__lte).toISOString()
    : new Date().toISOString();

  const fetchData = useCallback(async () => {
    setLoading(true);

    const customQuery: GetActivitiesInputType = {
      take: 1000, // just set to a large number to get all activities
    };

    if (search.trim()) {
      customQuery.search = search.trim();
    }

    if (activityTypes.length > 0) {
      customQuery.activityTypes = activityTypes;
    }

    if (locations.length > 0) {
      customQuery.locations = locations.map(Number);
    }

    if (districts.length > 0) {
      customQuery.districts = districts.map(Number);
    }

    if (dateTimeStart__gteDate) {
      customQuery.dateTimeStart__gte = dateTimeStart__gteDate;
    }

    if (dateTimeEnd__lteDate) {
      customQuery.dateTimeEnd__lte = dateTimeEnd__lteDate;
    }

    const _activities = await getActivities({ ...customQuery });
    setActivities(_activities);

    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = activities.items.map((activity) => {
    return {
      activity: new Activity(activity),
      resident: new Resident(activity.resident),
      location: new Location(activity.location),
      district: new District(activity.district),
      curfew: activity.curfew ? new Curfew(activity.curfew) : undefined,
    };
  });

  useEffect(() => {
    setGlobalLoading(loading);
    if (loading) return;

    setTimeout(() => {
      window.print();
    }, 200);
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.hide}>
        <button onClick={() => navigate(-1)}>Go Back</button>
        <button onClick={() => window.print()}>Print</button>
        <p>
          To remove the header and footer, click the &quot;More settings&quot;
          button below and uncheck the &quot;Headers and footers&quot; option.
        </p>
      </div>
      <h1>Activity Report</h1>
      <h2>Search Criteria</h2>
      <div className={styles.details}>
        <div>
          {search && (
            <div>
              <strong>Search:</strong> {search}
            </div>
          )}
          {activityTypes.length > 0 && (
            <div>
              <strong>Activity Types:</strong> {activityTypes.join(", ")}
            </div>
          )}
          {locations.length > 0 && (
            <div>
              <strong>Locations:</strong> {locations.join(", ")}
            </div>
          )}
          {districts.length > 0 && (
            <div>
              <strong>Districts:</strong> {districts.join(", ")}
            </div>
          )}
          <div>
            <strong>Date Range:</strong>
            {format(
              new Date(dateTimeStart__gteDate),
              "MM/dd/yyyy hh:mm a"
            )} - {format(new Date(dateTimeEnd__lteDate), "MM/dd/yyyy hh:mm a")}
          </div>
        </div>
        <div>
          <strong>Total Activities:</strong> {activities.total}
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Resident</th>
            <th>Date</th>
            <th>Activity Type</th>
            <th>Location</th>
            <th>District</th>
            <th>Supervision</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {rows.map(({ activity, resident, location, district }) => (
            <tr key={activity.id}>
              <td>
                {[resident.firstName, resident.lastName]
                  .filter(Boolean)
                  .join(" ")}
              </td>
              <td>
                {activity.createdAt &&
                  format(new Date(activity.createdAt), "MM/dd/yyyy hh:mm a")}
              </td>
              <td>{activity.type}</td>
              <td>{location.name}</td>
              <td>{district.name}</td>
              <td>{resident.supervision}</td>
              <td>
                {activity.type === "CURFEW" &&
                  activity.curfew &&
                  [activity.curfew.destination, activity.curfew.eta].join(", ")}

                {activity?.type === "RESIDENT_REPORT" &&
                  activity.residentReport?.report}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
