import React from "react";
import { TableSearchField } from "./index";
import { headerLinkHeight, bottomNavigationHeight, colors } from "../constants";
import format from "date-fns/format";
import { convertUTCToLocal } from "../utils/date-helpers";

import { Resident } from "../classes/resident";

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableContainer } from "./TableNew";
import { Grid, Typography } from "@mui/material";
import {
  LocationFilter,
  ManagersFilter,
  ResidentFilter,
  ResidentStatusFilter,
  TableFilterModal,
  DistrictFilter,
} from "./TableFilter";

interface RecordProps {
  resident: Resident;
}

const columnHelper = createColumnHelper<RecordProps>();

const defaultColumns = [
  columnHelper.accessor(
    (row) =>
      [row.resident.lastName, row.resident.firstName]
        .filter(Boolean)
        .join(", "),
    {
      header: "Name",
      cell: (info) => <strong>{info.getValue()}</strong>,
      size: 200,
    }
  ),
  columnHelper.accessor("resident.location.name", {
    header: "Location",
    size: 66,
  }),
  columnHelper.accessor("resident.supervision", {
    header: "Supervision",
    size: 120,
  }),
  columnHelper.accessor("resident.lastUaDate", {
    header: "Last UA",
    cell: (info) => {
      const value = info.getValue();
      return value && format(convertUTCToLocal(value), "M/dd/yy");
    },
    size: 86,
  }),
  columnHelper.accessor("resident.rentDueDate", {
    header: "Rent Due",
    cell: (info) => {
      const value = info.getValue();
      return value && format(convertUTCToLocal(value), "M/dd/yy");
    },
    size: 86,
  }),
];

const HeaderStats = ({ total }: { total: number }) => (
  <Grid
    container
    justifyContent="flex-end"
    gap={1}
    sx={{ backgroundColor: colors.grey[6], px: 1 }}
  >
    <Typography variant="body2">Count: {total}</Typography>
  </Grid>
);

interface ManagerTableProps {
  rows: RecordProps[];
  total: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  onRowClick?: (row: RecordProps) => void;
}

const ResidentTable = ({
  rows,
  total,
  setPage,
  search,
  setSearch,
  onRowClick,
}: ManagerTableProps) => {
  const filterContent = [
    ResidentStatusFilter,
    ResidentFilter,
    LocationFilter,
    DistrictFilter,
    ManagersFilter,
  ];

  const table = useReactTable({
    data: rows,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <TableFilterModal
        filterContent={filterContent}
        callback={() => setPage(0)}
      />
      <TableSearchField
        value={search}
        onChange={(e) => {
          setPage(0);
          // If user selects from the existing search list,
          // value will be empty and textContent will have the search string
          e.target.value
            ? setSearch(e.target.value)
            : setSearch(e.target.textContent ?? "");
        }}
        filterContent={filterContent}
      />
      <HeaderStats total={total} />
      <TableContainer<RecordProps>
        table={table}
        onRowClick={onRowClick}
        tableBodyHeight={`calc(100vh - ${headerLinkHeight} - ${bottomNavigationHeight} - 80px)`}
        total={total}
        onLoadMore={() => setPage((prev) => prev + 1)}
        emptyViewText="No residents"
      />
    </div>
  );
};

export default ResidentTable;
