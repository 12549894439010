import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DownloadIcon from "@mui/icons-material/Download";
import format from "date-fns/format";
import handleShare from "../utils/share-image";
import { useGlobalLoading } from "./GlobalLoading";

interface PhotoButtonProps<T> {
  photo: T;
  removePhoto: (photo: T) => Promise<void>;
  updatePhoto: (photo: T) => Promise<void>;
  uaSaveResident?: () => Promise<void>;
  uaUpdateResident?: () => Promise<void>;
}

export function PhotoButton<T extends { url?: string; createdAt?: string }>({
  photo,
  removePhoto,
  updatePhoto,
}: PhotoButtonProps<T>) {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{
          height: 50,
          backgroundImage: `url(${photo.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <PhotoViewer
        open={open}
        photo={photo}
        timestamp={photo.createdAt}
        onClose={handleClick}
        updatePhoto={updatePhoto}
        removePhoto={removePhoto}
      />
    </>
  );
}

export interface PhotoViewerProps<T> {
  open: boolean;
  onClose: () => void;
  photo: T;
  updatePhoto: (photo: T) => Promise<void>;
  removePhoto: (photo: T) => Promise<void>;
  timestamp?: string;
}

export function PhotoViewer<T extends { url?: string; caption?: string }>({
  open,
  onClose: _onClose,
  photo: _photo,
  updatePhoto,
  removePhoto,
  timestamp,
}: PhotoViewerProps<T>) {
  const [photo, setPhoto] = useState(_photo);
  const [isDirty, setIsDirty] = useState(false);
  const { setGlobalLoading } = useGlobalLoading();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setPhoto(_photo);
    setIsDirty(false);
  }, [_photo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    const _photo = photo.constructor({
      ...photo,
      [field]: e.target.value,
    });

    setPhoto(_photo);
    setIsDirty(true);
  };

  const onClose = async () => {
    if (isDirty) {
      setIsDirty(false);

      setGlobalLoading("Updating");
      await updatePhoto(photo);
      setGlobalLoading(false);
    }

    _onClose();
  };

  const handleRemove = async () => {
    setDeleting(true);

    if (confirm("Are you sure you want to delete this photo?")) {
      setGlobalLoading("Deleting");
      await removePhoto(photo);
      setGlobalLoading(false);
    }

    setDeleting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img
          style={{
            width: "100%",
            maxHeight: 300,
            objectFit: "contain",
          }}
          src={photo.url}
          alt="Photo Viewer"
        />
        <Grid
          container
          flexDirection="column"
          spacing={1}
          style={{
            marginTop: 16,
          }}
        >
          <Grid item>
            <TextField
              label="Caption"
              name="caption"
              value={photo.caption}
              onChange={handleChange}
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>

          {timestamp && (
            <Grid item>
              <Typography variant="body2">
                Created: {format(new Date(timestamp), "PPp")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton onClick={handleShare(photo.url as string)}>
            <DownloadIcon />
          </IconButton>
          <Button
            color="error"
            variant="text"
            onClick={handleRemove}
            startIcon={deleting ? null : <DeleteIcon />}
            disabled={deleting}
          >
            {deleting ? <CircularProgress /> : "Delete"}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

interface PhotoCreateButtonProps {
  uaSaveResident?: () => Promise<void>;
  uaUpdateResident?: () => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export const PhotoCreateButton = ({
  uaSaveResident,
  uaUpdateResident,
  onChange,
}: PhotoCreateButtonProps) => {
  const { setGlobalLoading } = useGlobalLoading();
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    setGlobalLoading("Uploading");
    await onChange(e);
    uaUpdateResident && (await uaUpdateResident());
    setGlobalLoading(false);
    setIsUploading(false);
  };

  return (
    <Grid item>
      <Button
        variant="contained"
        color="primary"
        aria-label="upload picture"
        component="label"
        sx={{
          height: 50,
        }}
        disabled={isUploading}
        onClick={async (event) => {
          try {
            setGlobalLoading("Saving Resident");
            uaSaveResident && (await uaSaveResident());
          } catch (e) {
            alert(
              "An error occurred while saving the resident. Please try again."
            );
            event.preventDefault();
          } finally {
            setGlobalLoading(false);
          }
        }}
      >
        <input hidden type="file" onChange={handleUpload} accept="image/*" />
        {!isUploading && <PhotoCameraIcon />}
        {isUploading && <CircularProgress />}
      </Button>
    </Grid>
  );
};

export const PhotoEmptyButton = () => (
  <Button
    variant="contained"
    color="primary"
    aria-label="upload picture"
    disabled
    sx={{
      height: 50,
    }}
  >
    <Typography>None</Typography>
  </Button>
);

export interface PhotosProps<T> {
  photos: T[];

  addPhoto: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  removePhoto: (photo: T) => Promise<void>;
  updatePhoto: (photo: T) => Promise<void>;

  editable?: boolean;
  saveFirst?: boolean;

  PhotoButtonComponent: React.FC<PhotoButtonProps<T>>;

  uaSaveResident?: () => Promise<void>;
  uaUpdateResident?: () => Promise<void>;
}

function Photos<T extends { id?: number }>({
  photos,

  addPhoto,
  removePhoto,
  updatePhoto,

  editable,
  saveFirst,

  PhotoButtonComponent,

  uaSaveResident,
  uaUpdateResident,
}: PhotosProps<T>) {
  const photoButtons = photos.map((photo) => {
    return (
      <Grid item key={photo.id}>
        <PhotoButtonComponent
          photo={photo}
          updatePhoto={updatePhoto}
          removePhoto={removePhoto}
          uaSaveResident={uaSaveResident}
          uaUpdateResident={uaUpdateResident}
        />
      </Grid>
    );
  });

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        {photoButtons}
        {editable && !saveFirst && (
          <PhotoCreateButton
            uaSaveResident={uaSaveResident}
            uaUpdateResident={uaUpdateResident}
            onChange={addPhoto}
          />
        )}
        {photos.length === 0 && (!editable || saveFirst) && (
          <Grid item>
            <PhotoEmptyButton />
          </Grid>
        )}
      </Grid>

      {saveFirst && (
        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
          Save first to add photos
        </Typography>
      )}
    </>
  );
}

export default Photos;
