import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SettingsDialog, PageLayout } from "./index";

import { useMe } from "../store/me";

import {
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HomeIcon from "@mui/icons-material/Home";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HandymanIcon from "@mui/icons-material/Handyman";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SendIcon from "@mui/icons-material/Send";

const SideMenu = ({
  handleSettingsOpen,
}: {
  handleSettingsOpen: () => void;
}) => {
  const { isAdmin } = useMe();

  return (
    <nav
      style={{
        flex: "0 0 11rem",
        backgroundColor: "#2d99d0",
      }}
    >
      <SideMenuItem to="residents" text="Residents" icon={<PeopleAltIcon />} />

      <SideMenuItem
        to="activity"
        text="Activity"
        icon={<FormatListBulletedIcon />}
      />

      <SideMenuItem to="locations" icon={<HomeIcon />} text="Locations" />

      {isAdmin() && (
        <SideMenuItem
          to="managers"
          icon={<SupervisorAccountIcon />}
          text="Managers"
        />
      )}

      <SideMenuItem
        to="collections"
        icon={<AttachMoneyIcon />}
        text="Collections"
      />

      <SideMenuItem
        to="maintenance"
        icon={<HandymanIcon />}
        text="Maintenance"
      />

      <SideMenuItem to="curfew" icon={<AccessTimeIcon />} text="Curfew" />

      <SideMenuItem to="send-sms" icon={<SendIcon />} text="Send SMS" />

      <ListItemButton onClick={handleSettingsOpen}>
        <ListItemIcon sx={{ color: "#fff", minWidth: 0, marginRight: 2 }}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" sx={{ color: "#fff" }} />
      </ListItemButton>
    </nav>
  );
};

const SideMenuItemStyles = styled(({ children, ...props }) => (
  <ListItemButton {...props}>{children}</ListItemButton>
))`
  &.active::after {
    content: "";
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;

    top: 50%;
    transform: translateY(-50%);

    z-index: 1;

    transition: all 0.2s ease-in-out;
  }
`;

const SideMenuItem = ({
  to,
  icon,
  text,
}: {
  to: string;
  icon: JSX.Element;
  text: string;
}) => (
  <SideMenuItemStyles component={NavLink} to={to}>
    <ListItemIcon sx={{ color: "#fff", minWidth: 0, marginRight: 2 }}>
      {icon}
    </ListItemIcon>
    <ListItemText primary={text} sx={{ color: "#fff" }} />
  </SideMenuItemStyles>
);

SideMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

const BottomNavigationActionStyles = styled(BottomNavigationAction)`
  &.active {
    color: #fff;
  }

  &.active::after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    left: 50%;
    transform: translateX(-50%);

    z-index: 1;

    transition: all 0.2s ease-in-out;
  }
`;

interface BottomTabProps {
  icon: JSX.Element;
  label: string;
  to: string;
  component: React.ElementType;
}

const BottomTab = ({ ...props }: BottomTabProps) => (
  <BottomNavigationActionStyles
    {...props}
    sx={{
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 80,
    }}
  />
);

const BottomTabs = ({
  handleSettingsOpen,
}: {
  handleSettingsOpen: () => void;
}) => {
  const { isAdmin } = useMe();

  return (
    <BottomNavigation
      showLabels
      aria-label="icon label tabs example"
      sx={{
        backgroundColor: "#2d99d0",
        justifyContent: "flex-start",
        overflow: "scroll",
        flexShrink: 0,
      }}
      component="nav"
    >
      <BottomTab
        icon={<PeopleAltIcon />}
        label="Residents"
        to="residents"
        component={NavLink}
      />
      <BottomTab
        icon={<FormatListBulletedIcon />}
        label="Activity"
        to="activity"
        component={NavLink}
      />
      <BottomTab
        icon={<HomeIcon />}
        label="Locations"
        to="locations"
        component={NavLink}
      />
      {isAdmin() && (
        <BottomTab
          icon={<SupervisorAccountIcon />}
          label="Managers"
          to="managers"
          component={NavLink}
        />
      )}
      <BottomTab
        icon={<AttachMoneyIcon />}
        label="Collections"
        to="collections"
        component={NavLink}
      />
      <BottomTab
        icon={<HandymanIcon />}
        label="Maintenance"
        to="maintenance"
        component={NavLink}
      />
      <BottomTab
        icon={<AccessTimeIcon />}
        label="Curfew"
        to="curfew"
        component={NavLink}
      />
      <BottomTab
        icon={<SendIcon />}
        label="Send SMS"
        to="send-sms"
        component={NavLink}
      />

      <BottomNavigationAction
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: 80,
        }}
        icon={<SettingsIcon />}
        label="Settings"
        onClick={handleSettingsOpen}
      />
    </BottomNavigation>
  );
};

BottomTabs.propTypes = {
  handleSettingsOpen: PropTypes.func.isRequired,
};

const SideMenuLayout = ({ children }: { children: JSX.Element }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      component="main"
      container
      direction={matches ? "row" : "column"}
      height="100vh"
      wrap="nowrap"
    >
      {matches && <SideMenu handleSettingsOpen={handleClickOpen} />}
      <section
        style={{
          flex: "1 1 auto",
          overflow: "scroll",
        }}
      >
        <PageLayout>{children}</PageLayout>
      </section>

      {!matches && <BottomTabs handleSettingsOpen={handleClickOpen} />}

      <SettingsDialog open={open} onClose={handleClose} />
    </Grid>
  );
};

SideMenuLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SideMenuLayout;
