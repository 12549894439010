import React, { useState, useRef, useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  GetDistrictsInputType,
  GetDistrictsOutputType,
  GetLocationsInputType,
  GetLocationsOutputType,
  GetResidentsInputType,
  useDistrictsApi,
  useLocationsApi,
  useResidentsApi,
} from "../apis";
import { useGlobalLoading } from "./GlobalLoading";

interface SendMessageModalProps {
  active: boolean;
  closeModal: () => void;
  customQuery: GetResidentsInputType;
  total: number;
}

export default function SendMessageModal({
  active,
  closeModal,
  customQuery,
  total,
}: SendMessageModalProps) {
  const { setGlobalLoading } = useGlobalLoading();

  const { sendSmsToResident } = useResidentsApi();
  const { getLocations } = useLocationsApi();
  const { getDistricts } = useDistrictsApi();

  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [locations, setLocations] = useState<GetLocationsOutputType>({
    total: 0,
    items: [],
    totalAvailableBeds: 0,
  });
  const [districts, setDistricts] = useState<GetDistrictsOutputType>({
    total: 0,
    items: [],
  });

  const sendSmsMessagesEl = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!active) return;
    if (!customQuery.locations) return;

    const query: GetLocationsInputType = {
      ids: customQuery.locations,
    };
    getLocations({ ...query }).then(setLocations);
  }, [active, customQuery.locations, getLocations]);

  useEffect(() => {
    if (!active) return;
    if (!customQuery.districts) return;

    const query: GetDistrictsInputType = {
      ids: customQuery.districts,
    };
    getDistricts({ ...query }).then(setDistricts);
  }, [active, getDistricts]);

  const handleSave: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (submitting) return;
    setSubmitting(true);
    setGlobalLoading(true);

    const _customQuery = { ...customQuery };

    // Remove pagination
    delete _customQuery.skip;
    delete _customQuery.take;

    await sendSmsToResident({ ..._customQuery, message });

    setSubmitting(false);
    setGlobalLoading(false);

    closeModal();
  };

  return (
    <Dialog open={active} onClose={closeModal} fullWidth>
      <DialogTitle>Send SMS Messages</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Filter</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>Resident Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {customQuery.statuses &&
                      ([] as string[])
                        .concat(customQuery.statuses)
                        .map((s) =>
                          s
                            .split("_")
                            .map(
                              (s) =>
                                s[0].toUpperCase() + s.slice(1).toLowerCase()
                            )
                            .join(" ")
                        )
                        .join(", ")}
                  </Typography>
                </TableCell>
              </TableRow>

              {customQuery.isGeo && (
                <TableRow>
                  <TableCell>
                    <Typography>Is Geo</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Yes</Typography>
                  </TableCell>
                </TableRow>
              )}

              {customQuery.hasNoRentDueDate && (
                <TableRow>
                  <TableCell>
                    <Typography>Has No Rent Due Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Yes</Typography>
                  </TableCell>
                </TableRow>
              )}

              {customQuery.search && (
                <TableRow>
                  <TableCell>
                    <Typography>Search</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{customQuery.search}</Typography>
                  </TableCell>
                </TableRow>
              )}

              {locations.total > 0 && (
                <TableRow>
                  <TableCell>
                    <Typography>Locations</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {locations.items.map((l) => l.name).join(", ")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {districts.total > 0 && (
                <TableRow>
                  <TableCell>
                    <Typography>Districts</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {districts.items.map((d) => d.name).join(", ")}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSave}>
          <Grid container direction="column" spacing={1} my={4}>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                minRows={3}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Used as a "submit" proxy for the DialogAction buttons */}
          <button
            ref={sendSmsMessagesEl}
            type="submit"
            style={{ display: "none" }}
          />
        </form>

        <Grid container justifyContent="flex-end" mt={2}>
          <Typography>Sending to {total} residents</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => sendSmsMessagesEl.current?.click()}
          disabled={submitting || !message || total === 0}
        >
          {submitting ? "Sending..." : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
