import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useApi } from "../../hooks/useApi";
import { User } from "../../classes/user";

interface Props {
  children: JSX.Element;
}

interface MeContextType {
  me?: User;
  fetchData: () => void;
  isAdmin: () => boolean;
  isDeveloper: () => boolean;
}

const Context = createContext<MeContextType>({
  fetchData: () => undefined,
  isAdmin: () => false,
  isDeveloper: () => false,
});
export const useMe = () => useContext(Context);
export const MeProvider = ({ children }: Props) => {
  const { fetchGET } = useApi();
  const [me, setMe] = useState<User>();

  const fetchData = useCallback(async () => {
    const _me = await fetchGET("me");
    setMe(_me);
  }, [fetchGET]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isAdmin = useCallback(() => {
    if (!me) return false;
    return me.roles.includes("ADMIN");
  }, [me]);

  const isDeveloper = useCallback(() => {
    if (!me) return false;
    return me.roles.includes("DEVELOPER");
  }, [me]);

  return (
    <Context.Provider
      value={{
        isAdmin,
        isDeveloper,
        fetchData,
        me,
      }}
    >
      {children}
    </Context.Provider>
  );
};
