import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { ActivityTable, HeaderLink, useTableFilter } from "../../../components";
import {
  useActivitiesApi,
  GetActivitiesInputType,
  GetActivitiesOutputType,
} from "../../../apis/activities";
import {
  Activity,
  Resident,
  Location,
  Curfew,
  ResidentReport,
} from "../../../classes";
import PrintIcon from "@mui/icons-material/Print";
import PrintModal from "../../../components/PrintModal";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function ActivityPage() {
  const { getActivities } = useActivitiesApi();
  const { locations, districts, activityTypes } = useTableFilter();
  const [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [activities, setActivities] = useState<GetActivitiesOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [active, setActive] = useState(false);

  // If user previously entered a search string, use that value for "search" variable below
  // When user navigates back to the activity page, their search string will be preserved in the UI
  const [search, setSearch] = useState(searchParams.get("search") ?? "");

  const fetchData = useCallback(async () => {
    const customQuery: GetActivitiesInputType = {
      skip: page * take,
      take,
    };

    if (search.trim()) {
      customQuery.search = search.trim();
      setSearchParams({ search: search.trim() });
    } else {
      setSearchParams({});
    }

    if (activityTypes.length > 0) {
      customQuery.activityTypes = activityTypes;
    }

    if (locations.length > 0) {
      customQuery.locations = locations;
    }

    if (districts.length > 0) {
      customQuery.districts = districts;
    }

    const _activities = await getActivities({ ...customQuery });
    if (page === 0) {
      setActivities(_activities);
    } else {
      setActivities({
        items: [...activities.items, ..._activities.items],
        total: _activities.total,
      });
    }
  }, [getActivities, page, take, search, activityTypes, locations, districts]);

  const handleLoadMore = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = activities.items.map((activity) => {
    console.log("activity", activity);
    return {
      activity: new Activity(activity),
      resident: new Resident(activity.resident),
      location: new Location(activity.location),
      curfew: activity.curfew ? new Curfew(activity.curfew) : undefined,
      residentReport: activity.residentReport
        ? new ResidentReport(activity.residentReport)
        : undefined,
    };
  });

  return (
    <>
      {matches && (
        <HeaderLink
          icon={<PrintIcon />}
          text="Print"
          onClick={() => setActive(true)}
        />
      )}

      <ActivityTable
        rows={rows}
        total={activities.total}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
      />

      <PrintModal active={active} closeModal={() => setActive(false)} />
    </>
  );
}
