import React, { useState, useCallback, useEffect } from "react";
import { HeaderLink, useTableFilter } from "../../../components";
import {
  useResidentsApi,
  GetResidentsInputType,
  GetResidentsOutputType,
} from "../../../apis/residents";
import ResidentTable from "../../../components/ResidentTableNew";
import SendMessagesModal from "../../../components/SendMessagesModal";
import { Location } from "../../../classes/location";
import SendIcon from "@mui/icons-material/Send";

export default function Messaging() {
  const { getResidents } = useResidentsApi();
  const { status, locations, districts, isGeo, hasNoRentDueDate } =
    useTableFilter();
  const [active, setActive] = useState(false);
  const [customQuery, setCustomQuery] = useState<GetResidentsInputType>({});
  const [residents, setResidents] = useState<GetResidentsOutputType>({
    items: [],
    total: 0,
  });

  const [page, setPage] = useState(0);
  const [take] = useState(100);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const _customQuery: GetResidentsInputType = {
      skip: page * take,
      take,
    };

    if (status.length > 0) {
      _customQuery.statuses = status;
    }

    if (locations.length > 0) {
      _customQuery.locations = locations;
    }

    if (districts.length > 0) {
      _customQuery.districts = districts;
    }

    if (isGeo) {
      customQuery.isGeo = isGeo;
    }

    if (hasNoRentDueDate) {
      customQuery.hasNoRentDueDate = hasNoRentDueDate;
    }

    if (search.trim()) {
      _customQuery.search = search.trim();
    }

    setCustomQuery(_customQuery);

    const _residents = await getResidents({ ..._customQuery });

    if (page === 0) {
      setResidents(_residents);
    } else {
      setResidents({
        items: [...residents.items, ..._residents.items],
        total: _residents.total,
      });
    }
  }, [
    page,
    take,
    status,
    locations,
    districts,
    isGeo,
    hasNoRentDueDate,
    search,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = residents.items.map((resident) => ({
    resident,
    location: resident.location as Location,
  }));

  return (
    <>
      <HeaderLink
        icon={<SendIcon />}
        text="Prepare SMS"
        onClick={() => setActive(true)}
      />

      <ResidentTable
        rows={rows}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        total={residents.total}
      />

      <SendMessagesModal
        active={active}
        closeModal={() => setActive(false)}
        total={residents.total}
        customQuery={customQuery}
      />
    </>
  );
}
